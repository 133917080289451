/* Skeleton Loader */
.skeleton {
  background-color: #e0e0e0;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  margin-bottom: 2rem;
  animation: skeleton-loading 1.5s infinite linear;
}

@keyframes skeleton-loading {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}

/* Gallery Container */
.galery__container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding-left: 200px;
  padding-right: 200px;
  gap: 2.5rem;
}

/* Gallery Items */
.galery__item {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}
.galery__item:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.galery__item-image,
.galery__item-video {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.galery__item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Tabs */
.galery__tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.galery__tabs button:hover {
  background-color: #007bff;
  color: #fff;
}

.galery__tabs button {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.galery__tabs button.active {
  background-color: #007bff;
  color: #fff;
}

/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: black;
  animation: fadeIn 0.5s ease forwards;
  overflow: auto;
}

.desc h3{
  color: #007bff;
  margin-bottom: 15px;
}

.desc p{
  text-align: left;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal__content {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  background: #fff;
  padding: 20px;
  max-width: 1280px;
  width: 100%;
  z-index: 1001;
  text-align: center;
  transition: transform 0.3s ease;
  animation: slideDown 0.5s ease forwards;
  max-height: 80vh; 
  overflow-y: auto;
}

@keyframes slideDown {
  from {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0);
  }
}

.modal__close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
}

/* Modal Image */
.modal__content img {
  max-width: 100%;
  max-height: 80vh; 
  object-fit: contain; 
  height: auto;
  margin-bottom: 20px;
}
.modal__content video{
  width: 100%; 
    max-height: 80vh; 
    object-fit: contain;
}

.modal__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Responsive */
@media screen and (max-width: 1024px) {
  .galery__container {
    grid-template-columns: 2fr 2fr;
    gap: 1.2rem;
    width: 100%;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .galery__container {
    grid-template-columns: 2fr 2fr;
    gap: 1rem;
    width: 100%;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
  }
  .modal__content {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    padding: 10px;
    overflow-y: auto; 
    max-height: 80vh;
    
  }

  .modal__content img {
    max-width: 100%;
    max-height: 100vh; 
  }
  .modal__content video {
    width: 100%; /* Pastikan video menggunakan 100% lebar modal */
    max-height: 100%; /* Batasi tinggi video tidak melebihi modal */
    object-fit: contain; /* Pastikan video di-scale secara proporsional */
  }

  .desc {
    margin-top: 10px; 
  }
}
