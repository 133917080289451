.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
  overflow: hidden; /* Ensures content inside does not overflow */
}

.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
  position: relative; /* Ensure image is positioned correctly within the container */
  width: 100%; /* Full width to fit the container */
  height: 200px; /* Fixed height for consistency, adjust as needed */
}

.portfolio__item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
}

.portfolio__item h3 {
  margin: 2rem 0;
}

.portfolio__item p {
  margin: 0 0 1.2rem 0;
  font-size: 0.9rem;
}

.portfolio__item-cta {
  display: flex;
  width: 100%;
  gap: 1rem;
  margin-bottom: 7rem;
  margin-left: auto;
  margin-right: auto;
}

.portfolio__item-content {
  height: auto; /* Remove fixed height to allow content to adjust naturally */
}

@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

.portfolio__tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.portfolio__tabs button {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.portfolio__tabs button.active {
  background-color: #007bff;
  color: #fff;
}

.portfolio__tabs button:hover {
  background-color: #007bff;
  color: #fff;
}

/* Keyframes for Animations */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Initial State Before Animation */
.portfolio__item {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

/* Active Class to Trigger Animation */
.portfolio__item.active {
  animation: fadeInUp 0.6s ease forwards;
}
/* Skeleton Loader Styles */
.skeleton {
  background: #e0e0e0;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
}

.skeleton-image {
  height: 200px; /* Adjust based on your design */
}

.skeleton-title {
  height: 1.5rem;
  width: 60%;
  margin: 0.5rem 0;
}

.skeleton-description,
.skeleton-technology {
  height: 1rem;
  width: 80%;
  margin: 0.5rem 0;
}

.skeleton-cta {
  height: 2rem;
  width: 40%;
  margin-top: 1rem;
}

/* Animation */
@keyframes loading {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

.skeleton {
  background: linear-gradient(45deg, transparent, var(--color-primary), transparent);;
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}
