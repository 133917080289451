.container.contact__container {
  display: flex;
  width: 30%;

}
#contact{
  padding-left: 20px;
  padding-right: 20px;
  animation: fadeInUp 1s ease-out forwards;
}

.contact__options {
  display: flex;
  justify-content: center;
  width: 100%;
}

.contact__option {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 1.2rem;
  border: 1px solid transparent;
  transition: var(--transition);
  display: flex;
  justify-content: center;
}

.contact__option-children {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.contact__option:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-right: 1rem;
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}

.contact__option h5 {
  color: var(--color-light);
}

.contact__option h4 {
  font-weight: 500;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
