/* Header Styling */
header {
  height: 100%;
  padding-top: 7rem;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInDown 1s ease-out forwards;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* CTA Button Animation */
.cta {
  display: flex;
  justify-content: center;
  gap: 1.2rem;
  margin-top: 2.5rem;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1.2s ease-out forwards;
}

.cta .btn {
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta .btn:hover {
  transform: scale(1.05);
}

/* Social Icons Animation */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 5rem;
  bottom: 1rem;
  opacity: 0;
  transform: translateX(-20px);
  animation: slideInLeft 1.5s ease-out forwards;
}

.header__socials::after {
  content: '';
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* Scroll Down Animation */
.scroll__down {
  position: absolute;
  right: 5rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
  opacity: 0;
  animation: fadeIn 2s ease-out forwards;
}

.scroll__down:hover {
  transform: rotate(90deg) scale(1.1);
  transition: transform 0.3s ease;
}

/* Keyframes for Animations */
@keyframes fadeInDown {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInLeft {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Responsive Design Adjustments */
@media screen and (max-width: 1024px){
  header {
    height: 68vh;
  }

  section {
    margin-top: 6rem;
  }

  .header__socials {
    left: 2rem;
    bottom: 2rem;
  }

  .scroll__down {
    right: 2rem;
    bottom: 2rem;
  }
}

@media screen and (max-width: 600px){
  header {
    height: 100vh;
  }

  .header__socials,
  .scroll__down {
    display: none;
  }
}
