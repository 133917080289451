/* Experience Section Container */
.experience__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s ease-out forwards;
}

.experience__container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    width: 60%;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1.2s ease-out forwards;
}

.experience__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}
.experience__frontend{
    text-align: center;
}
.title{
    margin-bottom: 30px;
}

/* Skill Details Styling */
.experience__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
    opacity: 0;
    animation: fadeIn 1.4s ease-out forwards;
}

.experience__details {
    display: flex;
    gap: 1rem;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1.6s ease-out forwards;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.experience__details:hover {
    transform: scale(1.05);
}

.experience__details-icon {
    margin-top: 6px;
    color: var(--color-primary);
    transition: color 0.3s ease;
}

.experience__details-icon:hover {
    color: var(--color-primary-variant);
}

/* Keyframes for Animations */
@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

/* Responsive Design Adjustments */
@media screen and (max-width: 1024px){
    .experience__container > div {
        width: 80%;
        margin: 0 auto;
        padding: 2rem;
    }

    .experience__content p {
        padding: 1rem;
    }
}

@media screen and (max-width: 600px){
    .experience__container {
        gap: 1rem;
    }

    .experience__container > div {
        width: 100%;
        padding: 2rem 1rem;
    }
}
