.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.8s ease-out forwards;
}

.about__container.animate {
    opacity: 1;
    transform: translateY(0);
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
    display: grid;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.about__me:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transition: transform 0.5s ease;
}

.about__me-image:hover {
    transform: scale(1.1);
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s ease-out 0.2s forwards;
}

.about__card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: background 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    transform: translateY(-10px);
    cursor: default;
}

.about__icon {
    color: var(--color-primary);
    font-size: 1.5rem;
    margin-bottom: 1rem;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1.2s ease-out 0.4s forwards;
}

.about__card h5 {
    font-size: 1rem;
}

.about__card small {
    font-size: 0.9rem;
    color: var(--color-light);
}

.about__content p {
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.8s ease-out forwards;
}

.about__content.animate p {
    opacity: 1;
    transform: translateY(0);
}

.btn-primary {
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-primary:hover {
    background-color: var(--color-primary-dark);
    transform: scale(1.05);
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__me {
        width: 50%;
        margin: 2rem auto 4rem; 
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
    }
}

@media screen and (max-width: 600px) {
    .about__me {
        width: 65%;
        margin: 0rem auto 3rem; 
    }

    .about__cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .about__content {
        text-align: center;
    }

    .about__content p {
        margin: 1.5rem 0;
    }
}
